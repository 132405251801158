<template>
  <div>
    <b-button class="w-100" v-b-modal="identificador" style="background-color: #b62e2c"
      >Solicitar Orçamento</b-button
    >

    <b-modal
      :id="identificador"
      ref="modal"
      :title="'Orçamento - ' + titulo"
      @show="clearForm"
      @hidden="clearForm"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <!-- NOME -->
        <b-form-group
          :state="state.nome"
          label="Nome"
          label-for="nome"
          invalid-feedback="Nome é obrigatório"
        >
          <b-form-input
            id="nome"
            v-model="formulario.nome"
            :state="state.nome"
            required
          ></b-form-input>
        </b-form-group>

        <!-- EMAIL -->
        <b-form-group
          :state="state.email"
          label="E-mail"
          label-for="email"
          invalid-feedback="E-mail é obrigatório"
        >
          <b-form-input
            id="email"
            v-model="formulario.email"
            :state="state.email"
            required
          >
          </b-form-input>
        </b-form-group>

        <!-- EMPRESA -->
        <b-form-group
            :state="state.empresa"
            label="Empresa"
            label-for="empresa"
        >
          <b-form-input
              id="empresa"
              v-model="formulario.empresa"
              :state="state.empresa"
          >
          </b-form-input>
        </b-form-group>

        <!-- TELEFONE -->
        <b-form-group
          :state="state.telefone"
          label="Telefone"
          label-for="telefone"
          invalid-feedback="Telefone é obrigatório"
        >
          <b-form-input
            id="telefone"
            v-model="formulario.telefone"
            :state="state.telefone"
            v-mask="['(##) ####-####', '(##) #####-####']"
            required
          >
          </b-form-input>
        </b-form-group>

        <!-- MENSAGEM -->
        <b-form-group
          :state="state.mensagem"
          label="Mensagem"
          label-for="mensagem"
          invalid-feedback="Mensagem é obrigatória"
        >
          <b-form-textarea
            id="mensagem"
            v-model="formulario.mensagem"
            :state="state.mensagem"
            required
          >
          </b-form-textarea>
        </b-form-group>
      </form>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              @click="handleOk"
              :disabled="state.form.send"
              variant="primary"
              class="float-right"
          >
            Enviar
          </b-button>
          <b-button
              @click="handleCancel"
              class="float-right mr-2"
          >
            Cancelar
          </b-button>
        </div>
      </template>

      <template v-slot:modal-cancel> Cancelar </template>
    </b-modal>
    <b-alert
      v-model="notificacaoEnviada"
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000; background-color: #152b5e"
      variant="border-0 text-light"
    >
      Mensagem enviada com sucesso!
    </b-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notificacaoEnviada: false,
      formulario: {
        nome: null,
        email: null,
        empresa: null,
        telefone: null,
        mensagem: null,
      },
      state: {
        nome: null,
        email: null,
        telefone: null,
        mensagem: null,
        form: {
          send: false
        }
      },
    };
  },
  props: ["identificador", "titulo"],
  methods: {
    clearForm() {
      this.formulario.nome = null;
      this.formulario.email = null;
      this.formulario.empresa = null;
      this.formulario.telefone = null;
      this.formulario.mensagem = null;

      this.state.nome = null;
      this.state.email = null;
      this.state.telefone = null;
      this.state.mensagem = null;
    },
    handleOk(modalEvent) {
      modalEvent.preventDefault();
      this.handleSubmit();
    },
    handleCancel(modalEvent) {
      modalEvent.preventDefault();
      this.$refs['modal'].hide();
    },
    handleSubmit() {
      this.state.nome = !!this.formulario.nome;
      this.state.email = !!this.formulario.email;
      this.state.telefone = !!this.formulario.telefone;
      this.state.mensagem = !!this.formulario.mensagem;

      if (
        !this.state.nome ||
        !this.state.email ||
        !this.state.telefone ||
        !this.state.mensagem
      )
        return;

        this.enviarOrcamento(this.formulario);
    },
    enviarOrcamento(data) {
      this.state.form.send = true;
      this.$http.post('https://8sv1c2vc0b.execute-api.us-east-2.amazonaws.com/prod/enviar', { ...data, tipo: 'orcamento', assunto: `Orçamento - ${this.titulo}` })
      .then(res => res.json)
      .then(() => {
        this.$refs['modal'].hide();
        this.notificacaoEnviada = true;
        this.state.form.send = false;
        setTimeout(() => {
          this.notificacaoEnviada = false;
        }, 5000);
        this.clearForm();
      }).catch(() => {
        this.state.form.send = false;
      });
    }
  },
};
</script>

<style>
</style>